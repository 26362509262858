import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  INVEST_OR_FUNDRAISING_SECTION_ID,
  WANT_TO_INVEST_SECTION_ID,
  WANT_FUNDRAISING_SECTION_ID,
  INVESTOR_SIGNUP_LINK,
  INVESTOR_LOGIN_LINK,
} from "../../utils/constants";

import logo from "../../assets/logo-header.png";
import brazilFlag from "../../assets/brazil-flag.png";
import portugalFlag from "../../assets/portugal-flag.png";
import englishFlag from "../../assets/english-flag.png";
import spainFlag from "../../assets/spain-flag.png";
import arrow_down from "../../assets/arrow_down.png";
import arrow_up from "../../assets/arrow_up.png";
import menu from "../../assets/circled-menu-48.png";

import "./style.css";
import "./mobile.css";

const BRA = "BRA";
const POR = "POR";
const ENG = "ENG";
const ESP = "ESP";

const FLAGS = {
  [BRA]: brazilFlag,
  [POR]: portugalFlag,
  [ENG]: englishFlag,
  [ESP]: spainFlag,
};

const DropdownMobile = ({ language }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <div className="dropdown-mobile">
      <Languages />
      <img className="menu" alt="" src={menu} />
      <div className="dropdown">
        <a className="buttons" href={`/#${INVEST_OR_FUNDRAISING_SECTION_ID}`}>
          {t("NavBar.learn_more")}
        </a>
        <a className="buttons" href={`/#${WANT_TO_INVEST_SECTION_ID}`}>
          {t("NavBar.invest")}
        </a>
        <a className="buttons" href={`/#${WANT_FUNDRAISING_SECTION_ID}`}>
          {t("NavBar.want_fundraising")}
        </a>
        <a
          className="button-login"
          href={`${INVESTOR_LOGIN_LINK}`}
          target="_blank"
          rel="noreferrer"
        >
          {t("NavBar.login")}
        </a>
        <a
          className="button-signup"
          href={`${INVESTOR_SIGNUP_LINK}`}
          target="_blank"
          rel="noreferrer"
        >
          {t("NavBar.signup")}
        </a>
      </div>
    </div>
  );
};

const Dropdown = ({ slectedFlag, onSelected }) => {
  return (
    <div className="dropdown">
      {slectedFlag !== BRA && (
        <div className="dropdown-item" onClick={() => onSelected(BRA)}>
          <img alt="" src={FLAGS[BRA]} />
          <span className="sigla">BRA</span>
        </div>
      )}
      {/* {slectedFlag !== POR && (
        <div className="dropdown-item" onClick={() => onSelected(POR)}>
          <img alt="" src={FLAGS[POR]} />
          <span className="sigla">POR</span>
        </div>
      )} */}
      {slectedFlag !== ENG && (
        <div className="dropdown-item" onClick={() => onSelected(ENG)}>
          <img alt="" src={FLAGS[ENG]} />
          <span className="sigla">ENG</span>
        </div>
      )}
      {/* {slectedFlag !== ESP && (
        <div className="dropdown-item" onClick={() => onSelected(ESP)}>
          <img alt="" src={FLAGS[ESP]} />
          <span className="sigla">ESP</span>
        </div>
      )} */}
    </div>
  );
};

const Languages = () => {
  const { i18n } = useTranslation();
  const [flag, setFlag] = useState(BRA);

  useEffect(() => {
    i18n.changeLanguage(flag.toLowerCase());
  }, [i18n, flag]);

  return (
    <div className="languages-container">
      <div className="language-selected">
        <div className="flag-language">
          <img alt="" src={FLAGS[flag]} />
          <span className="sigla">{flag}</span>
        </div>
        <img className="arrow-down" alt="" src={arrow_down} />
        <img className="arrow-up" alt="" src={arrow_up} />
      </div>
      <Dropdown slectedFlag={flag} onSelected={(item) => setFlag(item)} />
    </div>
  );
};

const HeaderSection = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // The debounce function receives our function as a parameter
    const debounce = (fn) => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame;

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params);
        });
      };
    };

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY;
    };

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });

    // Update scroll position for first time
    storeScroll();
  }, []);

  return (
    <div className="header-container">
      <img className="logo" alt="" src={logo} />
      <div className="buttons-container">
        <a className="buttons" href={`/#${INVEST_OR_FUNDRAISING_SECTION_ID}`}>
          {t("NavBar.learn_more")}
        </a>
        <a className="buttons" href={`/#${WANT_TO_INVEST_SECTION_ID}`}>
          {t("NavBar.invest")}
        </a>
        <a className="buttons" href={`/#${WANT_FUNDRAISING_SECTION_ID}`}>
          {t("NavBar.want_fundraising")}
        </a>
        <a
          href={`${INVESTOR_LOGIN_LINK}`}
          className="button-login"
          target="_blank"
          rel="noreferrer"
        >
          {t("NavBar.login")}
        </a>
        <a
          href={`${INVESTOR_SIGNUP_LINK}`}
          className="button-signup"
          target="_blank"
          rel="noreferrer"
        >
          {t("NavBar.signup")}
        </a>
        <Languages />
      </div>
      <DropdownMobile language={i18n.language} />
    </div>
  );
};

export default HeaderSection;
