import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import background from "../../assets/fundo022.png";
import logo from "../../assets/logo-header.png";
import brazilFlag from "../../assets/brazil-flag.png";

import {
  PUBLIC_WEB_APP_DOMAIN,
  PUBLIC_OFFERS_API,
} from "../../utils/constants";

import "./style.css";
import "./mobile.css";

const Flag = (country) => {
  switch (country) {
    case "usa":
      return brazilFlag;
    case "portugal":
      return brazilFlag;
    default:
      return brazilFlag;
  }
};

const calculateProgress = (percentage) =>
  Number(percentage) > 7
    ? (365 * Number(percentage)) / 100
    : Number(percentage) > 4
    ? (365 * Number(5)) / 100
    : 0;

const Card = ({
  t,
  offerId,
  comnpanyId,
  isPrivate = false,
  tech,
  country,
  banner,
  title,
  description,
  percentage,
}) => {
  return (
    <td className="container_card">
      <div className="banner_card">
        {isPrivate && (
          <p className="private">
            {t("OportunitiesSection.CardsContainer.Card.private")}
          </p>
        )}
        <div className="country">
          <img className="flag" alt="" src={Flag(country)} />
          <p className="tech">{tech}</p>
        </div>
        <img className="background-banner" alt="" src={banner} />
      </div>
      <div className="content_card">
        <div className="title_card">{title}</div>
        <div className="description_card"><p>{description}</p>
        <div className="fade"></div></div>
        {/* <div className="progress_container_card">
          <div className="progress_title_percent_card">
            <div className="progress_title_card">
              {t("OportunitiesSection.CardsContainer.Card.raised")}
            </div>
            <div className="progress_percentage_card">
              {parseFloat(percentage).toFixed(0)}%
            </div>
          </div>
          <div className="progress_bar_card">
            <div
              id="progress"
              className="progress"
              style={{
                width: `${calculateProgress(
                  parseFloat(percentage).toFixed(0)
                )}px`,
              }}
            />
          </div>
        </div> */}
        <a
          href={`${PUBLIC_WEB_APP_DOMAIN}/login?redirect=/companies/${comnpanyId}/offers/${offerId}`}
          target="_blank"
          rel="noreferrer"
          className="want_invest_button_card"
        >
          {t("OportunitiesSection.CardsContainer.Card.want_invest")}
        </a>
      </div>
      <img className="ubits_logo" alt="" src={logo} />
    </td>
  );
};

const OportunitiesSection = () => {
  const { t } = useTranslation();

  const [offers, setOffers] = useState([]);

  const fetchData = async () => {
    return fetch(PUBLIC_OFFERS_API)
      .then((response) => response.json())
      .then((data) => setOffers(data.filter((offer) => offer.is_private)));
      // .then((data) => setOffers(data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container__oportunities">
      <img className="background" alt="" src={background} />
      <div className="title_container">
        <span className="title">
          {t("OportunitiesSection.TitleContainer.title")}
        </span>
        <div className="title_underscore_container">
          <div className="underscore" />
          <div className="period" />
        </div>
        <span className="subtitle">
          {t("OportunitiesSection.TitleContainer.subtitle")}
        </span>
        <span className="subtitle1">
          {t("OportunitiesSection.TitleContainer.subtitle1")}
        </span>
      </div>
      {offers?.length > 0 && (
        <div className="container_cards">
          <table>
            <tr>
              {offers?.map((offer) => (
                <Card
                  t={t}
                  offerId={offer.uuid}
                  comnpanyId={offer.company_uuid}
                  isPrivate={offer.is_private}
                  tech={offer.operation_sectors[0]}
                  country={"brazil"}
                  banner={offer.banner_url}
                  title={offer.company_name}
                  description={offer.description}
                  percentage={offer.pencentage_of_funding_raised || 0}
                />
              ))}
            </tr>
          </table>
        </div>
      )}
      {offers?.length > 0 && (
        <a
          className="more_opportunities_button"
          href={`${PUBLIC_WEB_APP_DOMAIN}/login?redirect=/offers`}
          target="_blank"
          rel="noreferrer"
        >
          {t("OportunitiesSection.CardsContainer.more_opportunities")}
        </a>
      )}
      {offers?.length < 1 && (
        <span className="comming_soon">
          {t("OportunitiesSection.CardsContainer.title")}
        </span>
      )}
    </div>
  );
};

export default OportunitiesSection;
