import { useTranslation } from "react-i18next";

import {
  WANT_FUNDRAISING_SECTION_ID,
  COMPANY_SIGNUP_LINK,
} from "../../utils/constants";

import AdvantagesIcon from "../../assets/startups-071.png";

import "./style.css";
import "./mobile.css";

const AdvantageSection3 = () => {
  const { t } = useTranslation();
  return (
    <div
      id={WANT_FUNDRAISING_SECTION_ID}
      className="advantage-section3-container"
    >
      <img alt="" src={AdvantagesIcon} />
      <div className="message-container">
        <span className="title">{t("AdvantageSection3.title")}</span>
        <div className="subtitle">{t("AdvantageSection3.subtitle")}</div>
        <a
          className="button"
          href={COMPANY_SIGNUP_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {t("AdvantageSection3.button")}
        </a>
      </div>
    </div>
  );
};

export default AdvantageSection3;
