import { useTranslation } from "react-i18next";
import fundo from "../../assets/fundo4.png";
import "./style.css";
import "./mobile.css";

const SloganSection = () => {
  const { t } = useTranslation();
  return (
    <div className="slogan-section-container">
      <img alt="" src={fundo} />
      <div className="slogan-section-message-container">
        <span className="title">{t("SloganSection.title")}<span>{t("SloganSection.title2")}</span>
        </span>
      </div>
    </div>
  );
};

export default SloganSection;
