import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  INVEST_OR_FUNDRAISING_SECTION_ID,
  INVESTOR_SIGNUP_LINK,
  COMPANY_SIGNUP_LINK,
} from "../../utils/constants";

import cadastro from "../../assets/cadastro.png";
import escolha from "../../assets/escolha.png";
import investimento from "../../assets/investimento.png";
import lucro from "../../assets/lucro.png";
import oferta from "../../assets/oferta.png";
import investimentos from "../../assets/investimentos.png";
import contrato from "../../assets/contrato.png";
import saque from "../../assets/saque.png";

import "./style__invest.css";
import "./style__fundraising.css";
import "./mobile__invest.css";
import "./mobile__fundraising.css";

const InvestContainer = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <span className="title">
        {t("InvestOrFundraisingSection.Invest.title")}
      </span>
      <span className="subtitle">
        {t("InvestOrFundraisingSection.Invest.subtitle")}
      </span>
      <div className="invest_or_fundraising--container-cards">
        <div className="container-card-cloumn1">
          <div className="container-card">
            <div className="card">
              <img alt="" src={cadastro} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Invest.Card1.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Invest.Card1.subtitle")}
                </span>
              </div>
            </div>
          </div>
          <div className="container-card">
            <div className="card">
              <img alt="" src={escolha} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Invest.Card2.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Invest.Card2.subtitle")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-card-cloumn2">
          <div className="container-card">
            <div className="card">
              <img alt="" src={investimento} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Invest.Card3.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Invest.Card3.subtitle")}
                </span>
              </div>
            </div>
          </div>
          <div className="container-card">
            <div className="card">
              <img alt="" src={lucro} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Invest.Card4.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Invest.Card4.subtitle")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        className="button__start-now"
        href={INVESTOR_SIGNUP_LINK}
        target="_blank"
        rel="noreferrer"
      >
        {t("InvestOrFundraisingSection.Invest.button")}
      </a>
    </div>
  );
};

const FundraisingContainer = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <span className="title">
        {t("InvestOrFundraisingSection.Fundraising.title")}
      </span>
      <span className="subtitle">
        {t("InvestOrFundraisingSection.Fundraising.subtitle")}
      </span>
      <div className="invest_or_fundraising--container-cards">
        <div className="container-card-cloumn1">
          <div className="container-card">
            <div className="card">
              <img alt="" src={oferta} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Fundraising.Card1.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Fundraising.Card1.subtitle")}
                </span>
              </div>
            </div>
          </div>
          <div className="container-card">
            <div className="card">
              <img alt="" src={investimentos} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Fundraising.Card2.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Fundraising.Card2.subtitle")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-card-cloumn2">
          <div className="container-card">
            <div className="card">
              <img alt="" src={contrato} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Fundraising.Card3.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Fundraising.Card3.subtitle")}
                </span>
              </div>
            </div>
          </div>
          <div className="container-card">
            <div className="card">
              <img alt="" src={saque} />
              <div className="card-text-container">
                <span className="title">
                  {t("InvestOrFundraisingSection.Fundraising.Card4.title")}
                </span>
                <span className="subtitle">
                  {t("InvestOrFundraisingSection.Fundraising.Card4.subtitle")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        className="button__start-now"
        href={COMPANY_SIGNUP_LINK}
        target="_blank"
        rel="noreferrer"
      >
        {t("InvestOrFundraisingSection.Fundraising.button")}
      </a>
    </div>
  );
};

const InvestOrFundraisingSection = () => {
  const { t } = useTranslation();
  const [showInvestContainer, setShowInvestContainer] = useState(true);

  return (
    <div
      id={INVEST_OR_FUNDRAISING_SECTION_ID}
      className={`container__invest_or_fundraising${
        showInvestContainer ? "--invest" : "--fundraising"
      }`}
    >
      <div className="buttons_container">
        <button className="invest" onClick={() => setShowInvestContainer(true)}>
          {t("InvestOrFundraisingSection.Button.invest")}
        </button>
        <button
          className="fundraising"
          onClick={() => setShowInvestContainer(false)}
        >
          {t("InvestOrFundraisingSection.Button.fundraising")}
        </button>
      </div>
      {showInvestContainer && <InvestContainer />}
      {!showInvestContainer && <FundraisingContainer />}
    </div>
  );
};

export default InvestOrFundraisingSection;
