import { useTranslation } from "react-i18next";
import AdvantagesIcon from "../../assets/vantagens-071.png";

import { UBITS_WHATSAPP } from '../../utils/constants'

import "./style.css";
import "./mobile.css";

const AdvantageSection1 = () => {
  const { t } = useTranslation();
  return (
    <div className="advantage-section1-container">
      <img alt="" src={AdvantagesIcon} />
      <div className="message-container">
        <span className="title">{t("AdvantageSection1.title")}</span>
        <div className="subtitle">{t("AdvantageSection1.subtitle")}</div>
        <a className="button" href={UBITS_WHATSAPP} target="_blank" rel="noreferrer">{t("AdvantageSection1.button")}</a>
      </div>
    </div>
  );
};

export default AdvantageSection1;
