import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot,
  Image,
} from "pure-react-carousel";
import { useTranslation } from "react-i18next";

import { INVEST_OR_FUNDRAISING_SECTION_ID, WANT_TO_INVEST_SECTION_ID, WANT_FUNDRAISING_SECTION_ID } from '../../utils/constants'

import "pure-react-carousel/dist/react-carousel.es.css";
import "./style.css";
import "./mobile.css";

import ArrowRight from "../../assets/arrow-right.png";
import ArrowLeft from "../../assets/arrow-left.png";
import slide_1 from "../../assets/slide_1.jpg";
import slide_2 from "../../assets/slide_2.jpg";
import slide_3 from "../../assets/slide_3.jpg";

const Carousel = () => {
  const { t } = useTranslation();

  return (
    <CarouselProvider
      id="carousel"
      naturalSlideWidth={400}
      naturalSlideHeight={180}
      visibleSlides={1}
      totalSlides={3}
      isPlaying
      infinite
    >
      <div className="container">
        <Slider className="slider">
          <Slide index={0}>
            <div className="slide-container">
              <Image alt="" src={slide_1} />
              <div className="slide-container-degrade" />
              <div className="slide-message-container">
                <span className="title">{t("Carousel.Slide1.title")}</span>
                <span className="subtitle">
                  {t("Carousel.Slide1.subtitle")}
                </span>
                <a className="button" href={`/#${INVEST_OR_FUNDRAISING_SECTION_ID}`}>
                  {t("Carousel.Slide1.button")}
                </a>
              </div>
            </div>
          </Slide>
          <Slide index={1}>
            <div className="slide-container">
              <Image alt="" src={slide_2} />
              <span className="slide-container-degrade__slide2" />
              <div className="slide-message-container__slide2">
                <span className="title">{t("Carousel.Slide2.title")}</span>
                <span className="subtitle">
                  {t("Carousel.Slide2.subtitle")}
                </span>
                <a className="button" href={`/#${WANT_TO_INVEST_SECTION_ID}`}>
                  {t("Carousel.Slide2.button")}
                </a>
              </div>
            </div>
          </Slide>
          <Slide index={2}>
            <div className="slide-container">
              <Image alt="" src={slide_3} />
              <span className="slide-container-degrade" />
              <div className="slide-message-container">
                <span className="title">
                  {t("Carousel.Slide3.title")}
                </span>
                <span className="subtitle">
                  {t("Carousel.Slide3.subtitle")}
                </span>
                <a className="button" href={`/#${WANT_FUNDRAISING_SECTION_ID}`}>
                  {t("Carousel.Slide3.button")}
                </a>
              </div>
            </div>
          </Slide>
        </Slider>
        <ButtonBack className="buttonBack">
          <img alt="" src={ArrowLeft} />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <img alt="" src={ArrowRight} />
        </ButtonNext>
        <DotGroup
          className="dotGroup"
          renderDots={(props) => {
            const { currentSlide } = props;
            return (
              <>
                <Dot
                  className={`${currentSlide === 0 ? "dot-selected" : "dot"}`}
                  slide={0}
                />
                <Dot
                  className={`${currentSlide === 1 ? "dot-selected" : "dot"}`}
                  slide={1}
                />
                <Dot
                  className={`${currentSlide === 2 ? "dot-selected" : "dot"}`}
                  slide={2}
                />
              </>
            );
          }}
        />
      </div>
    </CarouselProvider>
  );
};

export default Carousel;
