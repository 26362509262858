import { useState } from "react";
import { useTranslation } from "react-i18next";

import background from "../../assets/fundo021.png";
import security_shield from "../../assets/security-shield.png";
import facility from "../../assets/facility.png";
import accessibility from "../../assets/accessibility.png";
import "./style.css";
import "./mobile.css";

const DEFAULT_STATE = [false, false, false];

const SecurityCard = ({ t, showHiddenCard }) => {
  return (
    <>
      {!showHiddenCard ? (
        <div className="card-security">
          <img alt="" src={security_shield} />
          <span>{t("AdvantagesSection.CardSecurity.title")}</span>
        </div>
      ) : (
        <div className="card-security-hide">
          <div className="title">
            {t("AdvantagesSection.CardSecurity.Hide.title")}
          </div>
          <div className="subtitle">
            {t("AdvantagesSection.CardSecurity.Hide.subtitle")}
          </div>
        </div>
      )}
    </>
  );
};

const FacilityCard = ({ t, showHiddenCard }) => {
  return (
    <>
      {!showHiddenCard ? (
        <div className="card-facility">
          <img alt="" src={facility} />
          <span>{t("AdvantagesSection.CardFacility.title")}</span>
        </div>
      ) : (
        <div className="card-facility-hide">
          <div className="title">
            {t("AdvantagesSection.CardFacility.Hide.title")}
          </div>
          <div className="subtitle">
            {t("AdvantagesSection.CardFacility.Hide.subtitle")}
          </div>
        </div>
      )}
    </>
  );
};

const AccessibilityCard = ({ t, showHiddenCard }) => {
  return (
    <>
      {!showHiddenCard ? (
        <div className="card-accessibility">
          <img alt="" src={accessibility} />
          <span>{t("AdvantagesSection.CardAccessibility.title")}</span>
        </div>
      ) : (
        <div className="card-accessibility-hide">
          <span className="title">
            {t("AdvantagesSection.CardAccessibility.Hide.title")}
          </span>
          <span className="subtitle">
            {t("AdvantagesSection.CardAccessibility.Hide.subtitle")}
          </span>
        </div>
      )}
    </>
  );
};

const Advantages = () => {
  const { t } = useTranslation();
  const [hiddenCards, setHiddenCards] = useState(DEFAULT_STATE);

  const toggleCard = (index) => {
    const newList = [...DEFAULT_STATE];
    newList[index] = !hiddenCards[index];
    setHiddenCards(newList);
  };

  return (
    <div className="container__advantages">
      <img alt="" src={background} />
      <h1 className="title">
        {t("AdvantagesSection.title")}
        <span className="period">.</span>
      </h1>
      <div className="container-cards">
        <div className="container-card" onClick={() => toggleCard(0)}>
          <SecurityCard t={t} showHiddenCard={hiddenCards[0]} />
        </div>
        <div className="container-card" onClick={() => toggleCard(1)}>
          <FacilityCard t={t} showHiddenCard={hiddenCards[1]} />
        </div>
        <div className="container-card" onClick={() => toggleCard(2)}>
          <AccessibilityCard t={t} showHiddenCard={hiddenCards[2]} />
        </div>
      </div>
    </div>
  );
};

export default Advantages;
