import { useEffect } from 'react'

import { INVESTOR_SIGNUP_LINK, COMPANY_SIGNUP_LINK, INVEST_HASHCODE, FUNDRAISE_HASHCODE } from './utils/constants'

import './App.css'
import Carousel from './components/Carousel'
import Advantages from './components/Advantages'
import AdvantageSection1 from './components/AdvantageSection1'
import AdvantageSection2 from './components/AdvantageSection2'
import AdvantageSection3 from './components/AdvantageSection3'
import SloganSection from './components/SloganSection'
import OportunitiesSection from './components/OportunitiesSection'
import InvestOrFundraisingSection from './components/InvestOrFundraisingSection'
import FAQSection from './components/FAQSection'
import FooterSection from './components/FooterSection'
import HeaderSection from './components/HeaderSection'
import ScrollTopButton from './components/ScrollTopButton'

function App() {
  // useEffect(() => {
  //   if (window.location.pathname === `/code/${FUNDRAISE_HASHCODE}`) {
  //     window.location.href = COMPANY_SIGNUP_LINK
  //   }

  //   if (window.location.pathname === `/code/${INVEST_HASHCODE}`) {
  //     window.location.href = INVESTOR_SIGNUP_LINK
  //   }
  // }, [])

  return (
    <div className="App">
      <HeaderSection />
      <Carousel />
      <Advantages />
      <AdvantageSection1 />
      <AdvantageSection2 />
      <AdvantageSection3 />
      <SloganSection />
      <OportunitiesSection />
      <InvestOrFundraisingSection />
      <FAQSection />
      <FooterSection />
      <ScrollTopButton />
    </div>
  )
}

export default App
