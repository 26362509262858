import { useTranslation } from "react-i18next";

import {
  WANT_TO_INVEST_SECTION_ID,
  INVESTOR_SIGNUP_LINK,
} from "../../utils/constants";

import AdvantagesIcon from "../../assets/investidores-071.png";

import "./style.css";
import "./mobile.css";

const AdvantageSection2 = () => {
  const { t } = useTranslation();
  return (
    <div
      id={WANT_TO_INVEST_SECTION_ID}
      className="advantage-section2-container"
    >
      <div className="message-container">
        <span className="title">{t("AdvantageSection2.title")}</span>
        <div className="subtitle">{t("AdvantageSection2.subtitle")}</div>
        <a
          className="button"
          href={INVESTOR_SIGNUP_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {t("AdvantageSection2.button")}
        </a>
      </div>
      <img alt="" src={AdvantagesIcon} />
    </div>
  );
};

export default AdvantageSection2;
