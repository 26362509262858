import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { UBITS_WHATSAPP } from '../../utils/constants'

import background from "../../assets/fundo021.png"
import arrow_down from "../../assets/arrow_down.png"
import arrow_up from "../../assets/arrow_up.png"

import "./style.css"
import "./mobile.css"

const Accordion = ({ title, text, open, onToggle }) => {
  if (open) {
    return (
      <div className="accordion__open" onClick={onToggle}>
        <div className="accordion__header">
          <span className="title">{title}</span>
          <img alt="" src={arrow_up} />
        </div>
        <div className="content">{text}</div>
      </div>
    )
  }
  return (
    <div className="accordion__closed" onClick={onToggle}>
      <span className="title">{title}</span>
      <img alt="" src={arrow_down} />
    </div>
  )
}

const DEFAULT_STATE = [false, false, false, false, false, false]

const FAQSection = () => {
  const { t } = useTranslation()
  const [accordions, setAccordions] = useState(DEFAULT_STATE)

  useEffect(() => {
    const isOpen = accordions.filter((a) => a === true)
    const { width } = window.screen
    let height = width >= 768 ? "990px" : "1300px"
    if (isOpen.length > 0) {
      height = width >= 768 ? "1200px" : "1500px"
    }
    document.getElementById("container__faq").style.maxHeight = height
    document.getElementById("container__faq").style.height = height
  }, [accordions])

  const toggleAccordion = (index) => {
    const newList = [...DEFAULT_STATE]
    newList[index] = !accordions[index]
    setAccordions(newList)
  }

  return (
    <div id="container__faq" className="container__faq">
      <img alt="" src={background} />
      <div className="title_container">
        <span className="title">
          {t("FAQSection.title")}
          <span className="period">.</span>
        </span>
      </div>
      <div className="container-accordions">
        <Accordion
          title={t("FAQSection.Questions.First.title")}
          text={t("FAQSection.Questions.First.text")}
          open={accordions[0]}
          onToggle={() => toggleAccordion(0)}
        />

        <Accordion
          title={t("FAQSection.Questions.Second.title")}
          text={t("FAQSection.Questions.Second.text")}
          open={accordions[1]}
          onToggle={() => toggleAccordion(1)}
        />
        <Accordion
          title={t("FAQSection.Questions.Third.title")}
          text={t("FAQSection.Questions.Third.text")}
          open={accordions[2]}
          onToggle={() => toggleAccordion(2)}
        />
        <Accordion
          title={t("FAQSection.Questions.Forth.title")}
          text={t("FAQSection.Questions.Forth.text")}
          open={accordions[3]}
          onToggle={() => toggleAccordion(3)}
        />
        <Accordion
          title={t("FAQSection.Questions.Fifth.title")}
          text={t("FAQSection.Questions.Fifth.text")}
          open={accordions[4]}
          onToggle={() => toggleAccordion(4)}
        />
        <div  className="container-footer">
          <span className="title">{t("FAQSection.Footer.title")}</span>
          <span className="subtitle">{t("FAQSection.Footer.subtitle")}</span>
          <a className="button" href={UBITS_WHATSAPP} target="_blank" rel="noreferrer">{t("FAQSection.Footer.button")}</a>
        </div>
      </div>
    </div>
  )
}

export default FAQSection
