import arrowUpWhite from "../../assets/arrow-up-white.png";
import "./style.css";

const ScrollTopButton = () => {
  return (
    <a className="button-scroll-top-container" href="/#carousel">
      <img alt="" src={arrowUpWhite} />
    </a>
  );
};

export default ScrollTopButton;
