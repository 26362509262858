import { useTranslation } from "react-i18next"
import logo from "../../assets/logo-white.png"
import logoCVM from "../../assets/logo-cvm.png"
import logoInstagram from "../../assets/instagram.png"
import logoFacebook from "../../assets/facebook.png"
import logoTwitter from "../../assets/twitter.png"
import logoLinkedin from "../../assets/linkedin.png"
import logoYoutube from "../../assets/youtube.png"
import "./style.css"
import "./mobile.css"

const LinksColumn1 = () => {
  const { t } = useTranslation()
  return (
    <div className="links-column-container">
      <span>{t("FooterSection.Links.Column1.title")}</span>
      <ul>
        <li>{t("FooterSection.Links.Column1.li1")}</li>
        <li>{t("FooterSection.Links.Column1.li2")}</li>
        <li>{t("FooterSection.Links.Column1.li3")}</li>
        <li>{t("FooterSection.Links.Column1.li4")}</li>
        <li>{t("FooterSection.Links.Column1.li5")}</li>
      </ul>
    </div>
  )
}

const LinksColumn2 = () => {
  const { t } = useTranslation()
  return (
    <div className="links-column-container">
      <span>{t("FooterSection.Links.Column2.title")}</span>
      <ul>
        <li>{t("FooterSection.Links.Column2.li1")}</li>
        <li>{t("FooterSection.Links.Column2.li2")}</li>
        <li>{t("FooterSection.Links.Column2.li3")}</li>
        <li>{t("FooterSection.Links.Column2.li4")}</li>
      </ul>
    </div>
  )
}

const LinksColumn3 = () => {
  const { t } = useTranslation()
  return (
    <div className="links-column-container">
      <span>{t("FooterSection.Links.Column3.title")}</span>
      <ul>
        <li>{t("FooterSection.Links.Column3.li1")}</li>
        <li>{t("FooterSection.Links.Column3.li2")}</li>
        <li>{t("FooterSection.Links.Column3.li3")}</li>
        <li>{t("FooterSection.Links.Column3.li4")}</li>
      </ul>
    </div>
  )
}

const LinksColumn4 = () => {
  const { t } = useTranslation()
  return (
    <div className="links-column-container">
      <span>{t("FooterSection.Links.Column4.title")}</span>
      <ul>
        <li>{t("FooterSection.Links.Column4.li1")}</li>
        <li>{t("FooterSection.Links.Column4.li2")}</li>
        <li>{t("FooterSection.Links.Column4.li3")}</li>
        <li>{t("FooterSection.Links.Column4.li4")}</li>
      </ul>
    </div>
  )
}

const LinksColumn5 = () => {
  const { t } = useTranslation()
  return (
    <div className="links-column-container">
      <span>{t("FooterSection.Links.Column5.title")}</span>
      <ul>
        <li>{t("FooterSection.Links.Column5.li1")}</li>
        <li>{t("FooterSection.Links.Column5.li2")}</li>
      </ul>
    </div>
  )
}

const FooterSectionMobile = () => {
  const { t } = useTranslation()
  return (
    <div className="footer-container-mobile">
      <div className="links-container">
        <div className="social-media-container">
          <a href="https://www.instagram.com/ubits.capital/" target="_blank" rel="noreferrer"><img alt="" src={logoInstagram} /></a>
          <a href="https://www.facebook.com/ubits.capital" target="_blank" rel="noreferrer"><img alt="" src={logoFacebook} /></a>
          <a href="https://www.linkedin.com/company/ubits-capital/" target="_blank" rel="noreferrer"><img alt="" src={logoLinkedin} /></a>
          <a href="https://twitter.com/uBits_Capital" target="_blank" rel="noreferrer"><img alt="" src={logoTwitter} /></a>
          <a href="https://www.youtube.com/c/uBitsCapital" target="_blank" rel="noreferrer"><img alt="" src={logoYoutube} /></a>
        </div>
        <div className="links">
          {/* <LinksColumn1 />
          <LinksColumn2 />
          <LinksColumn3 />
          <LinksColumn4 /> */}
          <LinksColumn5 />
        </div>
      </div>
      <div className="cvm-rules-message-container">
        <span>{t("FooterSection.CVM.rules")}</span>
      </div>
      <div className="copyright-container">
        <span>{t("FooterSection.copyright", { currentYear: new Date().getFullYear() })}</span>
      </div>
      <div className="cvm-logo-container">
        <img alt="" src={logoCVM} />
      </div>
    </div>
  )
}

const FooterSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <FooterSectionMobile />
      <div className="footer-container">
        <div className="links-container">
          <img className="logo" alt="" src={logo} />
          <div className="links">
            {/* <LinksColumn1 />
            <LinksColumn2 />
            <LinksColumn3 />
            <LinksColumn4 /> */}
            <LinksColumn5 />
          </div>
        </div>
        <div className="cvm-rules-message-container">
          <span>{t("FooterSection.CVM.rules")}</span>
        </div>
        <div className="copyright-container">
          <span>{t("FooterSection.copyright", { currentYear: new Date().getFullYear() })}</span>
          <div className="social-media-container">
            <a href="https://www.instagram.com/ubits.capital/" target="_blank" rel="noreferrer"><img alt="" src={logoInstagram} /></a>
            <a href="https://www.facebook.com/ubits.capital" target="_blank" rel="noreferrer"><img alt="" src={logoFacebook} /></a>
            <a href="https://www.linkedin.com/company/ubits-capital/" target="_blank" rel="noreferrer"><img alt="" src={logoLinkedin} /></a>
            <a href="https://twitter.com/uBits_Capital" target="_blank" rel="noreferrer"><img alt="" src={logoTwitter} /></a>
            <a href="https://www.youtube.com/c/uBitsCapital" target="_blank" rel="noreferrer"><img alt="" src={logoYoutube} /></a>
          </div>
        </div>
        <div className="cvm-logo-container">
          <img alt="" src={logoCVM} />
        </div>
      </div>
    </>
  )
}

export default FooterSection
